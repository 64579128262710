<template>
  <div>
    <ejs-dialog
        ref="lostItemDetailInfoPopup"
        header="분실물 정보 상세"
        :animationSettings="animationSettings"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        width="1000"
        :close="closeLostItemDetailPopup"
    >
      <div class="window lostImageInfoConfig">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">분실물 정보 상세</div>
                    </div>
                  </div>
                  <div class="section-body custom-section-body">
                    <article class="body-article">
                      <div class="article-wrapper">
                        <section class="article-section section-0102" style="width: 50%">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">분실 정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li
                                    class="field full-row"
                                    v-if="lostInfo.lostId"
                                  >
                                    <!-- 필수 : required -->
                                    <div class="title">분실ID</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-text
                                              ref="lostIdInput"
                                              :disabled="true"
                                              v-model="lostInfo.lostId"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title required">분실일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-date
                                              ref="lostDate"
                                              :format="DATE_FORMAT_YYYY_MM_DD"
                                              v-model="lostInfo.lostDate"
                                              type="body-data"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title required">분실구분</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="lostDiv"
                                                v-model="lostInfo.lostDiv"
                                                :dataSource="commonCodesGetCommonCode('LOST_DIV')"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title required">물품명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="productName"
                                              v-model="lostInfo.productName"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">물품상세정보</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-textarea
                                              ref="productNameDtl"
                                              id="productNameDtlInput"
                                              v-model="lostInfo.productNameDtl"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">분실장소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="lostPlace"
                                              v-model="lostInfo.lostPlace"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">보관장소</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="kepPlace"
                                              v-model="lostInfo.kepPlace"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">분실자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              ref="lostPersonName"
                                              id="lostPersonNameInput"
                                              v-model="lostInfo.lostPersonName"
                                              @change="onLostPersonNameChanged"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">분실자연락처</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <component-telephone
                                              id="lostPersonContactTelInput"
                                              v-model="lostInfo.lostPersonContactTel"
                                              :max-length="11"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">비고</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-textarea
                                              id="remarksInput"
                                              v-model="lostInfo.remarks"
                                              :height="!lostInfo.lostId ? 54 : 30"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                        <section class="article-section section-0102" style="width: 50%">
                          <div class="section-header">
                            <div class="header-left">
                              <div class="header-title">이미지 정보</div>
                            </div>
                          </div>
                          <div class="section-body">
                            <div class="body-data">
                              <div class="data-outer">
                                <div class="data-inner">
                                  <ul class="data-content">
                                    <div
                                      class="prev-button"
                                      @click="moveScroll(true)"
                                    />
                                    <div
                                      ref="imageList"
                                      id="upload-body"
                                      class="upload-wrapper"
                                    >
                                      <div
                                        v-if="fixedImageInfo.before"
                                        class="before"
                                        :style="{width: `${(imageBoxWidth + 18) / 3}px`}"
                                      />
                                      <div
                                        v-if="fixedImageInfo.after"
                                        class="after"
                                        :style="{width: `${(imageBoxWidth + 18) / 3}px`}"
                                      />
                                      <div
                                        class="upload-box"
                                        v-for="(item, index) in imageList" :key="index"
                                      >
                                        <div class="image">
                                          <div
                                            v-if="item.imageUrl"
                                            class="item"
                                            :style="{
                                              ...imageBoxCss,
                                              backgroundImage: `url(${item.imageUrl})`,
                                            }"
                                          />
                                        </div>
                                        <div
                                          class="view-button"
                                          :data-id="item.imageId"
                                          :data-image-url="item.imageUrl"
                                          :data-sort-no="item.sortNo"
                                          @mousedown="onMouseDown"
                                          @click="onShowImageClicked(index)"
                                        />
                                        <div class="sort-no-mark">
                                          {{ item.sortNo + 1 }}
                                        </div>
                                        <erp-button
                                          button-div="FILE"
                                          class="download"
                                          :use-syncfusion-component-style="false"
                                          @click="onDownloadImageClicked(item)"
                                        />
                                        <erp-button
                                          button-div="DELETE"
                                          class="delete"
                                          :use-syncfusion-component-style="false"
                                          @click="onDeleteProfileImageClicked(item)"
                                        />
                                      </div>
                                      <div class="upload-box new">
                                        <div class="image">
                                          <div
                                            class="item"
                                            :style="imageBoxCss"
                                          />
                                        </div>
                                        <div class="view-button" />
                                        <input
                                          type="file"
                                          multiple="multiple"
                                          :accept="ACCEPT_FILE_INPUT"
                                          @change="onFileChange"
                                          @click="onFileClicked"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      class="next-button"
                                      @click="moveScroll(false)"
                                    />
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0103">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">내장객 방문 정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">내장객명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item input form-group">
                                          <div class="form">
                                            <input-text
                                              ref="visitName"
                                              id="visitNameInput"
                                              v-model.trim="lostInfo.visitName"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">내장일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-date
                                              :format="DATE_FORMAT_YYYY_MM_DD"
                                              v-model="lostInfo.visitDate"
                                              type="body-data"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">예약시간</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-time
                                              id="resveTimeInput"
                                              name="resveTimeInput"
                                              v-model="lostInfo.resveTime"
                                              format="HH:mm"
                                              :text-align="'left'"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">예약코스</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              id="resveCourseDropdown"
                                              v-model="lostInfo.resveCourse"
                                              :dataSource="getCourseCode"
                                              :fields="commonCodeFields"
                                              :allowFiltering="false"
                                              cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">캐디명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item input form-group">
                                          <div class="form">
                                            <input-text
                                              ref="caddieName"
                                              id="caddieNameInput"
                                              v-model.trim="lostInfo.caddieName"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">투숙일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-date
                                              :format="DATE_FORMAT_YYYY_MM_DD"
                                              v-model="lostInfo.stayDate"
                                              type="body-data"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">객실번호</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item input form-group">
                                          <div class="form">
                                            <input-text
                                              ref="roomNo"
                                              id="roomNoInput"
                                              v-model="lostInfo.roomNo"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0104">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">반환/습득 정보</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">습득자명</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item input form-group">
                                          <div class="form">
                                            <input-text
                                              ref="finderName"
                                              id="finderNameInput"
                                              v-model.trim="lostInfo.finderName"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">반환일자</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-date
                                              :format="DATE_FORMAT_YYYY_MM_DD"
                                              v-model="lostInfo.returnDate"
                                              type="body-data"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field">
                                    <!-- 필수 : required -->
                                    <div class="title">반환방법</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                id="returnMethodDropdown"
                                                v-model="lostInfo.returnMethod"
                                                :dataSource="commonCodesGetCommonCode('RETURN_METHOD')"
                                                :fields="commonCodeFields"
                                                :allowFiltering="false"
                                                cssClass="body-data-dropdown"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field full-row">
                                    <!-- 필수 : required -->
                                    <div class="title">반환내용</div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          <div class="form">
                                            <input-textarea
                                              id="returnContents"
                                              v-model="lostInfo.returnContents"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="print">
              <erp-button
                button-div="PRINT"
                @click.native="onLostItemPrint"
              >
                발행
              </erp-button>
            </li>
            <li class="delete" v-if="lostInfo">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeLostItemDetailPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import {validateFormRefs} from "@/utils/formUtil";
import {commonCodesGetCommonCode, commonCodeGetComCodeDefaultValue, commonCodesGetComName} from "@/utils/commonCodes";
import {deepDiffs} from "@/utils/ObjectUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDate from "@/components/common/datetime/InputDate";
import InputText from "@/components/common/text/InputText";
import InputTime from "@/components/common/datetime/InputTime";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {updateImageSortNo, uploadImage} from "@/utils/formUtil";
import {maxBy as _maxBy, sortBy as _sortBy} from "lodash";
import moment from "moment";
import {mapGetters} from "vuex";
import ErpButton from "@/components/button/ErpButton";
import BillPrintUtil from "@/utils/billPrintUtil";

const IMAGE_BOX_WIDTH = 285;
const DELAY_MOVE_SCROLL = 300;
const IMAGE_PATTERN_FOR_LOST = "LOST";

export default {
  name: "LostItemDetailInfoPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTime,
    InputTextarea,
    InputText,
    InputDate,
    ErpButton,
  },
  created(){
    if (!this.lostInfo.lostId) {
      this.lostInfo.lostDate = moment().format("YYYY-MM-DD");
      this.lostInfo.lostDiv = commonCodeGetComCodeDefaultValue("LOST_DIV");
      this.lostInfo.finderName = this.username;
    }
  },
  data() {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      ACCEPT_FILE_INPUT : ["image/png", "image/gif", "image/jpeg", "image/jpg", "image/bmp"],
      imageList: [],
      fixedImageInfo: {
        start: null,
        end: null,
        imageUrl: null,
        orgSortNo: null,
        sortNo: null,
      },
      imageBoxWidth: IMAGE_BOX_WIDTH,
      imageBoxCss: {
        width: `${IMAGE_BOX_WIDTH}px`,
        height: '258px',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      },
      imageIdBuff: null,
      isMoveScrollVisible: true,
      commonCodeFields: {text: "comName", value: "comCode"},
      animationSettings: {effect: "None"},
      lostInfo: {
        lostId: null,
      },
      lostInfoBuff: {},
      validateRules: {
        lostDate: {
          required: true,
        },
        lostDiv: {
          required: true,
        },
        productName: {
          required: true,
          maxLength: 100,
        },
        productNameDtl: {
          maxLength: 400,
        },
        lostPlace: {
          maxLength: 400,
        },
        kepPlace: {
          maxLength: 400,
        },
        lostPersonName: {
          maxLength: 100,
        },
        visitName: {
          maxLength: 100,
        },
        caddieName: {
          maxLength: 100,
        },
        roomNo: {
          maxLength: 10,
        },
        finderName: {
          maxLength: 100,
        },
      },
    };
  },
  methods: {
    commonCodesGetCommonCode,
    validateFormRefs,
    async showPopup(data) {
      this.lostInfoBuff = {...data} || {};
      this.lostInfo = data || {};
      setTimeout(() => {
        this.$refs.lostDate.focus();
      }, 50);
      await this.fetchImages();
    },
    async closeLostItemDetailPopup() {
      await this.$emit("popupClosed", {isRefresh: false,popupIdentifier: 'LostItemDetailInfoPopup'});
    },
    async onSaveButtonClicked() {
      const diffs = deepDiffs(this.lostInfoBuff, this.lostInfo);
      if(!diffs){
        this.errorToast("변경사항이 없습니다.");
        return;
      }
      if (!this.validateFormRefs(this.validateRules)) {
        return;
      }
      Object.keys(diffs).map(key => {
        if (['visitDate', 'stayDate', 'returnDate'].includes(key) && !diffs[key]) {
          diffs[key] = '';
        }
      });

      if(this.lostInfo.lostId) {
        await GolfErpAPI.patchLostItems([{
          lostId: this.lostInfo.lostId,
          ...diffs,
        }]);
      } else {
        this.lostInfo.lostId = await GolfErpAPI.postLostItem({
          ...diffs,
        });
      }
      this.infoToast("저장되었습니다.");
      await this.$emit("popupClosed", {isRefresh: true});
      this.lostInfoBuff = {...this.lostInfo};
    },
    async onDeleteButtonClicked() {
      //TODO
      if(await this.confirm("자료를 삭제하시겠습니까?")){
        await GolfErpAPI.deleteLostItems([this.lostInfo.lostId]);
        await this.deleteImageFile(null, true);
        this.infoToast("삭제되었습니다.");
        await this.$emit("popupClosed", {isRefresh: true, popupIdentifier: 'LostItemDetailInfoPopup'});
      }
    },
    async moveScroll(isPrev = false, isSmooth = true) {
      if (!this.isMoveScrollVisible) {
        return;
      }
      await this.$nextTick();
      const distance = this.imageBoxWidth + 18;
      const imageList = this.$refs.imageList;
      imageList.scrollTo({ left: imageList.scrollLeft + (isPrev ? -distance : distance), behavior: isSmooth ? 'smooth' : 'auto' });
      this.isMoveScrollVisible = false;
      setTimeout(() => this.isMoveScrollVisible = true, DELAY_MOVE_SCROLL);
    },
    async fetchImages() {
      const data = await GolfErpAPI.getLostItemImages(this.lostInfo.lostId);
      this.imageList = _sortBy(data.map(item => {
        return {
          imageId: item.imageId,
          imageUrl: item.imagePath,
          sortNo: item.sortNo || 0,
        };
      }), 'sortNo');
    },
    onFileClicked(e) {
      if(!this.lostInfo.lostId) {
        this.errorToast("분실물 정보를 저장 후 이미지를 업로드 하여 주십시오.");
        e.preventDefault();
        return;
      }
    },
    async onFileChange(args) {
      const uploadFileProc = async (file, idx) => {
        const data = await uploadImage(
            null,
            IMAGE_PATTERN_FOR_LOST,
            [{
              statusCode: '1',
            }],
            file,
            idx,
        );
        if (data?.imageId && data?.imageURL) {
          this.imageList.push({
            imageId: data?.imageId,
            imageUrl: data?.imageURL,
            sortNo: idx,
          });
          this.imageList = _sortBy(this.imageList,'sortNo');
        }
        await GolfErpAPI.saveLostItemImageFileRecord({
          lostId : this.lostInfo?.lostId,
          imageId: data?.imageId,
        });
      };
      try {
        let maxSortNo = 0;
        if(this.imageList.length > 0){
          maxSortNo = Number(_maxBy(this.imageList, "sortNo").sortNo) + 1;
        }
        if (args.target.files.length > 1) {
          const validateResult = Array.prototype.every.call(args.target.files, (file) => this.ACCEPT_FILE_INPUT.includes(file.type));
          if (!validateResult) {
            this.errorToast("지원 가능한 업로드 형식의 파일이 존재합니다.");
            return;
          }
          await Promise.all(Array.prototype.map.call(args.target.files, (file, idx) => {
            uploadFileProc(file, idx + maxSortNo);
          }));
        } else if (args.target.files.length === 1) {
          const file = args.target.files[0];
          if (!this.ACCEPT_FILE_INPUT.includes(file.type)) {
            this.errorToast("지원 가능한 업로드 형식이 아닙니다.");
            return;
          }
          await uploadFileProc(file, maxSortNo);
        }
      }
      catch (e) {
        alert(e.message);
      }
    },
    async onDeleteProfileImageClicked(item) {
      if (item?.imageUrl && item?.imageId) {
        if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
          return;
        }
        await this.deleteImageFile(item);
        this.infoToast(this.$t('main.popupMessage.deleted'));
      }
    },
    async deleteImageFile(item, isDeleteAll = false) {
      try {
        if(!isDeleteAll) {
          const findIndex = item && this.imageList
              ?.find(i => i.imageId === item.imageId);
          if (findIndex < 0) {
            return;
          }
          delete this.imageList[findIndex];
          await GolfErpAPI.deleteImage(item.imageId, IMAGE_PATTERN_FOR_LOST);
          await GolfErpAPI.deleteLostItemImageFileSoft({
            lostId: this.lostInfo?.lostId,
            imageId: item?.imageId,
          });

          await this.fetchImages();
        } else {
          await Promise.all(this.imageList.map((image) => {
            GolfErpAPI.deleteImage(image.imageId, IMAGE_PATTERN_FOR_LOST);
            GolfErpAPI.deleteLostItemImageFileSoft({
              lostId: this.lostInfo?.lostId,
              imageId: image.imageId,
            });
          }));
        }
      } catch (e) {
        console.error('imageDelete.err.===>', e);
      }
    },
    onDownloadImageClicked(item) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", item.imageUrl + "?" + (new Date()).getTime(), true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement("a");
        tag.href = imageUrl;
        tag.download = `sample-image-${item.sortNo}.png`;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.send();
    },
    async onMoveImageClicked(index, to = 'prev') {
      const self = this.imageList[index];
      const next = this.imageList[index + (to === 'prev' ? -1 : 1)];
      if (self && next) {
        const selfSortNo = self.sortNo;
        const nextSortNo = next.sortNo;
        self.sortNo = nextSortNo;
        next.sortNo = selfSortNo;
        this.imageList = _sortBy(this.imageList, ['sortNo']);
        await updateImageSortNo(self.imageId, nextSortNo);
        await updateImageSortNo(next.imageId, selfSortNo);
      }
    },
    onShowImageClicked(index) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          toolbar: {
            prev: {
              size: "large",
            },
            next: {
              size: "large",
            },
          }
        },
        images: this.imageList.map(item => item.imageUrl),
      });
    },
    onMouseDown(event) {
      event.preventDefault();
      document.onmousemove = this.onMouseMove;
      document.onmouseup = this.onMouseUp;
    },
    onLostPersonNameChanged() {
      if(!this.lostInfo.visitName) {
        this.lostInfo.visitName = this.lostInfo.lostPersonName;
        this.$forceUpdate();
      }
    },
    async onLostItemPrint(){

      const printSetData = {
        lostId: this.lostInfo.lostId,
        lostDate: this.lostInfo.lostDate,
        lostDiv : commonCodesGetComName("LOST_DIV", this.lostInfo.lostDiv),
        productName: this.lostInfo.productName,
        productNameDtl : this.lostInfo.productNameDtl,
        lostPlace : this.lostInfo.lostPlace,
        kepPlace : this.lostInfo.kepPlace,
        lostPersonName : this.lostInfo.lostPersonName,
        lostPersonContactTel : this.lostInfo.lostPersonContactTel,
        remarks : this.lostInfo.remarks,
      };

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };

      await BillPrintUtil.getTsConfReceiptInfo.call(this, "LOST_ITEM" , true , printSetData , config);
      console.log(printSetData);
    },
  },
  computed: {
    ...mapGetters(["username"]),
    getCourseCode() {
      const codes = commonCodesGetCommonCode('COURSE_CODE');
      codes.unshift({comCode: "", comName: ""});
      return codes;
    },
  }
};
</script>

<style scoped>
.article-wrapper {display: flex; flex-direction: row}
.full-row { flex : 1 1 100% }
body .window.lostImageInfoConfig .data-content {display: flex}
body .window.lostImageInfoConfig .data-content > .prev-button,
body .window.lostImageInfoConfig .data-content > .next-button {width: 64px; background-color: #fafafa; background-size: 80%; background-position: center; background-repeat: no-repeat; opacity: .5; cursor: pointer}
body .window.lostImageInfoConfig .data-content > .prev-button:hover,
body .window.lostImageInfoConfig .data-content > .next-button:hover {opacity: 1}
body .window.lostImageInfoConfig .data-content > .prev-button {background-image: url(../../../assets/images/common/image-prev.png); border-right: 1px solid #ccc}
body .window.lostImageInfoConfig .data-content > .next-button {background-image: url(../../../assets/images/common/image-next.png); border-left: 1px solid #ccc}
body .window.lostImageInfoConfig .upload-wrapper {display: flex; overflow-x: scroll; width: 319px}
body .window.lostImageInfoConfig .upload-wrapper::-webkit-scrollbar {height: 0}
body .window.lostImageInfoConfig .before,
body .window.lostImageInfoConfig .after {position: absolute; top: 0; left: 61px; width: 100px; height: 100%; background: linear-gradient(to right, #C1F3FF, transparent); opacity: .8}
body .window.lostImageInfoConfig .after {left: unset; right: 61px; background: linear-gradient(to left, #C1F3FF, transparent)}
body .window.lostImageInfoConfig .upload-box {position: relative}
body .window.lostImageInfoConfig .upload-box > .image {display: flex; align-items: center; margin: 1rem; height: 236px; border: 1px solid #ccc; border-radius: 5px}
body .window.lostImageInfoConfig .upload-box:not(:last-child) > .image {margin-right: 0}
body .window.lostImageInfoConfig .upload-box > .image > .item {border-radius: 5px}
body .window.lostImageInfoConfig .upload-box .view-button {position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer}
body .window.lostImageInfoConfig .upload-box:hover .view-button,
body .window.lostImageInfoConfig .upload-box.new .view-button {background-image: url(../../../assets/images/common/image-expand.png); background-size: 10%; background-position: center; background-repeat: no-repeat; opacity: .5}
body .window.lostImageInfoConfig .upload-box.new .view-button {background-image: url(../../../assets/images/common/image-upload-black.png)}
body .window.lostImageInfoConfig .upload-box.new:hover .view-button {opacity: .8}
body .window.lostImageInfoConfig .upload-box input {position: absolute; top: 0px; right: 0px; width: 32px; height: 32px; opacity: 0; cursor: pointer !important}
body .window.lostImageInfoConfig .upload-box.new input {position: absolute; top: 0; left: 0; width: calc(100% - 2rem); height: calc(100% - 2rem); margin: 1rem; opacity: 0; cursor: pointer}
body .window.lostImageInfoConfig .upload-box .sort-no-mark {position: absolute; top: 1rem; left: 1rem; width: 37px; height: 37px; line-height: 36px; border-top-left-radius: 5px; background-color: #2e7458; font-family: NanumSquare-Regular, serif; font-size: 18px; font-weight: bold; text-align: center; color: #FFF}
body .window.lostImageInfoConfig button.move,
body .window.lostImageInfoConfig button.download,
body .window.lostImageInfoConfig button.delete {position: absolute; top: calc(1rem + 5px); right: 5px; width: 32px; height: 32px; border: 0; border-radius: 5px; background-size: 70%; background-position: center; background-repeat: no-repeat; background-color: rgba(0, 0, 0, .5); cursor: pointer}
body .window.lostImageInfoConfig button.move.prev {right: 153px; background-image: url(../../../assets/images/common/image-prev-white.png)}
body .window.lostImageInfoConfig button.move.next {right: 116px; background-image: url(../../../assets/images/common/image-next-white.png)}
body .window.lostImageInfoConfig button.move.prev.pull-right {right: 116px}
body .window.lostImageInfoConfig button.download {right: 42px; background-image: url(../../../assets/images/common/image-download.png)}
body .window.lostImageInfoConfig button.delete {background-image: url(../../../assets/images/common/image-remove.png)}
body .window.lostImageInfoConfig button.move:hover,
body .window.lostImageInfoConfig button.download:hover,
body .window.lostImageInfoConfig button.delete:hover {opacity: .5}
</style>