<template>
  <div>
    <ejs-dialog
        ref="LostDivReturnDetailPopup"
        header="반환완료 정보"
        :width="450"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :animationSettings="{ effect: 'None' }"
        :close="onLostDivReturnDetailPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-title">반환완료 정보</div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field full-row">
                              <!-- 필수 : required -->
                              <div class="title required">반환일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                          ref="returnDate"
                                          :format="DATE_FORMAT_YYYY_MM_DD"
                                          v-model="lostInfo.returnDate"
                                          type="body-data"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field full-row">
                              <!-- 필수 : required -->
                              <div class="title required">반환방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="returnMethod"
                                          id="returnMethodDropdown"
                                          v-model="lostInfo.returnMethod"
                                          :dataSource="commonCodesGetCommonCode('RETURN_METHOD')"
                                          :fields="commonCodeFields"
                                          :allowFiltering="false"
                                          cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field full-row">
                              <!-- 필수 : required -->
                              <div class="title required">반환내용</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                          ref="returnContents"
                                          :height="200"
                                          id="returnContentsInput"
                                          v-model="lostInfo.returnContents"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="windowFooter">
        <ul class="button">
          <li class="save keyColor">
            <erp-button
                button-div="SAVE"
                :is-key-color="true"
                :is-shortcut-button="true"
                @click.native="onSaveButtonClicked"
            >
              저장
            </erp-button>
          </li>
          <li class="close">
            <erp-button
                button-div="CLOSE"
                v-on:click.native="onLostDivReturnDetailPopupClosed"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.full-row {
  flex : 1 1 100%;
}
</style>

<script>
import {  commonCodesGetCommonCode } from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import inputDate from "@/components/common/datetime/InputDate";
import inputTextarea from "@/components/common/text/InputTextarea";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import moment from "moment";
import {validateFormRefs} from "@/utils/formUtil";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LostDivReturnDetailPopup",
  components: {
    inputDate,
    inputTextarea,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      lostInfo: {
        returnDate : moment().format("YYYY-MM-DD"),
        returnMethod : null,
        returnContents : null
      },
      commonCodeFields: {text: "comName", value: "comCode"},
      validateRules: {
        returnDate: {
          required: true,
        },
        returnMethod: {
          required: true,
        },
        returnContents: {
          required: true,
        },
      },
    };
  },
  methods: {
    validateFormRefs,
    commonCodesGetCommonCode,
    onSaveButtonClicked() {
      if(!this.validateFormRefs(this.validateRules)){
        return;
      }
      this.$emit("submitted", this.lostInfo);
    },
    onLostDivReturnDetailPopupClosed() {
      this.$emit("popupClosed", {isRefresh: false, popupIdentifier: 'LostDivReturnDetailPopup'});
    },
  },
};
</script>