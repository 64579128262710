<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">분실일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="searchDateInputDateRange"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">분실구분</div>
            <ul class="content">
              <li class="item dateRange">
                <ejs-dropdownlist
                  cssClass="lookup-condition-dropdown"
                  :allowFiltering="false"
                  :dataSource="dataSourceLostDiv"
                  :fields="commonCodeFields"
                  v-model="searchOptions.searchLostDiv"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">물품명/물품상세정보</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.productName"
                  @keydown.enter="searchLostItems"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">분실자명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.lostPersonName"
                  @keydown.enter="searchLostItems"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="searchLostItems"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">분실 목록</div>
              <div class="header-caption">[{{ this.lostItems.length }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="selectClick"
                  >
                    선택처리
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="lostItemRegistration.shortcuts.addRostItem"
                      :shortcut="{key: 'F3'}"
                      @click.native="onClickAddRecord"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      @click.native="onClickDeleteRecord"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="lostItemListGrid"
                :allowExcelExport="true"
                :allow-paging="true"
                :page-settings="{pageSize: 50}"
                :provides="gridProvides"
                :selection-settings="selectionSettings"
                :is-auto-select-cell="false"
                :is-auto-select-row="false"
                :is-selected-row-retain="false"
                :data-source="lostItems"
                :columns="gridColumns"
                @recordClick="onRecordClicked"
                @queryCellInfo="onGridQueryCellInfo"
                @headerCellInfo="onGridHeaderCellInfo"
              >
              </ejs-grid-wrapper>
            </div>
          </div>
        </section>
      </article>
    </div>
    <lost-item-detail-info-popup
      ref="lostItemDetailInfoPopup"
      v-if="isLostItemDetailInfoPopupOpen"
      @popupClosed="popupClosed"
    />
    <lost-div-change-popup
      ref="lostDivChangePopup"
      v-if="isLostDivChangePopupOpen"
      @popupClosed="popupClosed"
      @lostDivChanged="lostDivChanged"
      @returnCodeSelected="onReturnCodeSelected"
    />
    <lost-div-return-detail-popup
      ref="lostDivReturnDetailPopup"
      v-if="isLostDivReturnDetailPopupOpen"
      @popupClosed="popupClosed"
      @submitted="returnDetailSubmitted"
    />
  </div>
</template>

<script>
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {Edit, ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetColorValue, commonCodesGetCommonCode} from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import inputText from "@/components/common/text/InputText";
import inputDateRange from "@/components/common/datetime/InputDateRange";
import LostItemDetailInfoPopup from "@/views/voice-of-the-customer/popup/LostItemDetailInfoPopup";
import moment from "moment/moment";
import LostDivChangePopup from "@/views/voice-of-the-customer/popup/LostDivChangePopup";
import LostDivReturnDetailPopup from "@/views/voice-of-the-customer/popup/LostDivReturnDetailPopup";
import {getFormattedPhoneNumber} from "@/utils/string";
import {orderBy as _orderBy} from "lodash";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LostItemRegistration",
  components: {
    LostDivChangePopup,
    LostItemDetailInfoPopup,
    LostDivReturnDetailPopup,
    EjsGridWrapper,
    inputText,
    inputDateRange,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      clickableFields: ['lostDiv', 'productName', 'productNameDtl', 'lostPlace', 'lostPersonName', 'lostPersonContactTel'],
      isLostItemDetailInfoPopupOpen: false,
      isLostDivChangePopupOpen: false,
      isLostDivReturnDetailPopupOpen: false,
      lostItems: [],
      gridColumns: [
        {
          field: "lostId",
          type: "number",
          isPrimaryKey: true,
          visible: false,
          allowFiltering: false,
          allowEditing: false,
        },
        {
          field: "flag",
          type: "checkbox",
          minWidth: 16,
          width: 40,
          textAlign: "Center",
          allowFiltering: false,
        },
        {
          allowEditing: false,
          field: "lostDate",
          type: "string",
          headerText: "분실일자",
          textAlign: "Center",
          width: 90,
        },
        {
          allowEditing: false,
          field: "lostDiv",
          headerText: "분실구분",
          type: "string",
          textAlign: "Center",
          width: 75,
          groupCode: "LOST_DIV",
          isCommonCodeField: true,
        },
        {
          allowEditing: false,
          field: "productName",
          type: "string",
          headerText: "물품명",
          textAlign: "Left",
          width: 180,
        },
        {
          allowEditing: false,
          field: "productNameDtl",
          type: "string",
          headerText: "물품상세정보",
          textAlign: "Left",
          width: 300,
        },
        {
          allowEditing: false,
          field: "lostPlace",
          headerText: "분실장소",
          type: "string",
          textAlign: "Left",
          width: 160,
        },
        {
          allowEditing: false,
          field: "kepPlace",
          headerText: "보관장소",
          type: "string",
          textAlign: "Left",
          width: 160,
        },
        {
          allowEditing: false,
          field: "lostPersonName",
          headerText: "분실자명",
          type: "string",
          textAlign: "Left",
          width: 120,
        },
        {
          minWidth: 16,
          allowEditing: false,
          field: "lostPersonContactTel",
          headerText: "분실자연락처",
          type: 'string',
          valueAccessor: this.telNumberValueAccess,
          textAlign: "Center",
          width: 100,
        },
        {
          allowEditing: false,
          field: "remarks",
          headerText: "비고",
          type: "string",
          textAlign: "left",
          width: 400,
        },
        {
          allowEditing: false,
          field: "visitName",
          headerText: "내장객",
          type: "string",
          textAlign: "left",
          width: 80,
        },
        {
          allowEditing: false,
          field: "visitDate",
          headerText: "방문일자",
          type: "string",
          textAlign: "Center",
          width: 90,
        },
        {
          allowEditing: false,
          field: "resveTime",
          headerText: "예약시간",
          type: "string",
          textAlign: "Center",
          width: 70,
        },
        {
          allowEditing: false,
          field: "resveCourse",
          headerText: "예약코스",
          type: "string",
          textAlign: "Center",
          width: 100,
          groupCode: "COURSE_CODE",
          isCommonCodeField: true,
        },
        {
          allowEditing: false,
          field: "caddieName",
          headerText: "캐디명",
          type: "string",
          textAlign: "Left",
          width: 80,
        },
        {
          allowEditing: false,
          field: "stayDate",
          headerText: "투숙일자",
          type: "string",
          textAlign: "Center",
          width: 90,
        },
        {
          allowEditing: false,
          field: "roomNo",
          headerText: "객실번호",
          type: "string",
          textAlign: "Center",
          width: 70,
        },
        {
          allowEditing: false,
          field: "finderName",
          headerText: "습득자명",
          type: "string",
          textAlign: "Left",
          width: 80,
        },
        {
          allowEditing: false,
          field: "returnDate",
          headerText: "반환일자",
          type: "string",
          textAlign: "Center",
          width: 80,
        },
        {
          allowEditing: false,
          field: "returnMethod",
          headerText: "반환방법",
          type: "string",
          textAlign: "Center",
          groupCode: "RETURN_METHOD",
          isCommonCodeField: true,
          width: 80,
        },
        {
          allowEditing: false,
          field: "returnContents",
          headerText: "반환내용",
          type: "string",
          textAlign: "Left",
          width: 160,
        },
        {
          allowEditing: false,
          field: "insertId",
          headerText: "등록자",
          type: "string",
          textAlign: "Left",
          width: 100,
        },
        {
          allowEditing: false,
          field: "insertDt",
          headerText: "등록일시",
          type: "string",
          textAlign: "Center",
          width: 140,
        },
        {
          allowEditing: false,
          field: "updateId",
          headerText: "수정자",
          type: "string",
          textAlign: "Left",
          width: 100,
        },
        {
          allowEditing: false,
          field: "updateDt",
          headerText: "수정일시",
          type: "string",
          textAlign: "Center",
          width: 140,
        },
      ],
      gridProvides: [Edit, ForeignKey, Resize, ExcelExport, Page],
      selectionSettings: {
        persistSelection: true,
        type: "Single",
        checkboxOnly: false,
        checkboxMode: "ResetOnRowClick",
        enableToggle: false,
      },
      commonCodeFields: {text: "comName", value: "comCode"},
      dataSourceLostDiv: null,
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        searchLostDiv: null,
        productName: null,
        lostPersonName: null,
      },
    };
  },
  created() {
    this.dataSourceLostDiv = commonCodesGetCommonCode("LOST_DIV");
    this.dataSourceLostDiv.unshift({comCode: "", comName: "전체"});

    this.searchOptions.searchLostDiv = this.dataSourceLostDiv[0].comCode;
    this.searchOptions.bsnDateFrom = moment().subtract("2", "M").format("YYYY-MM-DD");
    this.searchOptions.bsnDateTo = moment().format("YYYY-MM-DD");
  },
  methods: {
    async searchLostItems() {
      const records = await GolfErpAPI.getLostItemList({
        fromDate: this.searchOptions.bsnDateFrom,
        toDate: this.searchOptions.bsnDateTo,
        lostDiv: this.searchOptions.searchLostDiv,
        productName: this.searchOptions.productName,
        lostPersonName: this.searchOptions.lostPersonName,
      });
      this.lostItems = _orderBy(records, ["lostDate", "productName"], ["desc", "asc"]);
    },
    selectClick() {
      if(this.$refs.lostItemListGrid.getSelectedRecords().length <= 0) {
        this.errorToast("분실 자료를 선택하여 주십시오.");
        return;
      }
      this.isLostDivChangePopupOpen = true;
    },
    async onClickAddRecord() {
      this.isLostItemDetailInfoPopupOpen = true;
    },
    async onClickDeleteRecord() {
      if(this.$refs.lostItemListGrid.getSelectedRecords().length < 1) {
        this.errorToast("자료를 선택하여 주십시오.");
        return;
      }
      if(await this.confirm("선택된 자료를 삭제하시겠습니까?")){
        const selectedRecords = this.$refs.lostItemListGrid.getSelectedRecords();
        await GolfErpAPI.deleteLostItems(selectedRecords.map(data => data.lostId));
        this.$refs.lostItemListGrid.clearSelection();
        this.$refs.lostItemListGrid.selectRow(selectedRecords[0]._rid - 2);
        this.infoToast("삭제되었습니다.");
        await this.searchLostItems();
      }
    },
    onClickExcel() {
      this.$refs.lostItemListGrid.excelExport();
    },
    onGridQueryCellInfo(args) {
      const {
        cell,
        column: {
          field,
        },
        data,
      } = args;
      if (field === "flag") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === 'lostDiv') {
        cell.style.color = commonCodesGetColorValue("LOST_DIV", data.lostDiv);
      }
      if (this.clickableFields.includes(field)) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;
      if (field === "grpRceptMemberCount") {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onRecordClicked(args){
      const {
        column: {field},
        rowData,
      } = args;
      if(this.clickableFields.includes(field)){
        this.isLostItemDetailInfoPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.lostItemDetailInfoPopup.showPopup(rowData);
        });
      }
    },
    async popupClosed({isRefresh, popupIdentifier}) {
      switch(popupIdentifier){
        case "LostItemDetailInfoPopup" :
          this.isLostItemDetailInfoPopupOpen = false;
          break;
        case "LostDivChangePopup" :
          this.isLostDivChangePopupOpen = false;
          break;
        case "LostDivReturnDetailPopup" :
          this.isLostDivReturnDetailPopupOpen = false;
          break;
        default:
          break;
      }
      if(isRefresh) {
        await this.searchLostItems();
      }
    },
    async lostDivChanged(code) {
      this.isLostDivChangePopupOpen = false;
      const updateItems = this.$refs.lostItemListGrid.getSelectedRecords().map(data => {
        return {
          lostId: data.lostId,
          lostDiv: code,
        };
      });
      await GolfErpAPI.patchLostItems(updateItems);
      await this.searchLostItems();
      this.infoToast("분실구분 상태를 변경하였습니다.");
    },
    async returnDetailSubmitted({returnDate, returnMethod, returnContents}) {
      this.isLostDivReturnDetailPopupOpen = false;
      const updateItems = this.$refs.lostItemListGrid.getSelectedRecords().map(data => {
        return {
          lostId: data.lostId,
          lostDiv: "RETURN",
          returnDate: returnDate,
          returnMethod: returnMethod,
          returnContents: returnContents,
        };
      });
      await GolfErpAPI.patchLostItems(updateItems);
      await this.searchLostItems();
      this.infoToast("분실구분 상태를 변경하였습니다.");
    },
    onReturnCodeSelected() {
      this.isLostDivChangePopupOpen = false;
      this.isLostDivReturnDetailPopupOpen = true;
    },
    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },
  },
  computed: {
    searchDateInputDateRange: {
      get: function () {
        return {
          from: this.searchOptions.bsnDateFrom,
          to: this.searchOptions.bsnDateTo
        };
      },
      set: function (dateRange) {
        this.searchOptions.bsnDateFrom = dateRange.from;
        this.searchOptions.bsnDateTo = dateRange.to;
      }
    },
  }
};
</script>

<style scoped>

</style>