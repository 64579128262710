<template>
  <div>
    <ejs-dialog
        ref="LostDivChangePopup"
        header="분실구분 변경"
        :width="300"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :animationSettings="{ effect: 'None' }"
        :close="onLostDivChangePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-title">분실구분 변경</div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div
                        v-for="(code, index) in lostDivCommonCodes"
                        :key="index"
                    >
                      <div
                          class="toggle"
                          :style="{
                          backgroundColor: `${code.colorValue}`,
                        }"
                          @click="onClickLostDivCodeItem(code)"
                      >
                        {{ code.comName }}
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div class="windowFooter">
        <ul class="button">
          <li class="close">
            <erp-button
                button-div="CLOSE"
                v-on:click.native="onLostDivChangePopupClosed"
            >
              닫기
            </erp-button>
          </li>
        </ul>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.toggle {
  margin: 10px 0;
  padding: 16px 0;
  font-size: 1.8em;
  font-family: "NanumSquare-Bold";
  text-align: center;
  color: black;
  border-radius: 10px;
  border: 3px solid gray;
}
.toggle:hover {
  cursor: pointer;
  filter: brightness(90%);
}
</style>
<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "LostDivChangePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      lostDivCommonCodes: null,
    };
  },
  async mounted() {
    this.lostDivCommonCodes = commonCodesGetCommonCode("LOST_DIV");
  },
  methods: {
    onLostDivChangePopupClosed() {
      this.$emit("popupClosed", {isRefresh: false, popupIdentifier: 'LostDivChangePopup'});
    },
    async onClickLostDivCodeItem(code) {
      if (code.comCode === "RETURN") {
        this.$emit("returnCodeSelected");
      } else {
        this.$emit("lostDivChanged", code.comCode);
      }
    },
  },
};
</script>